







































































































































import {Component, Prop} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import GroupAvatar from '@/components/GroupAvatar.vue';
import {copyTextToClipboard, twoChars, unsubscribeSafe} from '@/utils/helpers';
import ContactInfoCard from '@/components/ContactInfoCard.vue';
import VcSwitch from '@/components/VCSwitch.vue';
import {Unsubscribe} from 'firebase/firestore';
import DialogEditAssociateContactInfo from '@/components/DialogEditAssociateContactInfo.vue';
import DialogConfirm from '@/components/DialogConfirm.vue';
import ContactInfoGeneral from '@/components/ContactInfoGeneral.vue';
import ContactInfoAssignment from '@/components/ContactInfoAssignment.vue';
import DepartmentInfoMembers from '@/components/DepartmentInfoMembers.vue';
import {applicationStore} from '@/store/modules/application';
import {directoryStore} from '@/store/modules/directory/directoryStore';
import {BusinessContact} from '@/domain/model/types';
import {Route} from 'vue-router';
import {profileStore} from '@/store/modules/profile';
import PopupMenu from '@/components/PopupMenu.vue';
import {mixins} from 'vue-class-component';
import ContentNotFound from '@/components/ContentNotFound.vue';
import Notifications from '@/components/mixins/Notifications';
import ContainerCalc from '@/components/mixins/ContainerCalc';

Component.registerHooks(['beforeRouteEnter', 'beforeRouteUpdate'])

@Component({
  name: 'contact-info2',
  components: {
    PopupMenu,
    DepartmentInfoMembers,
    ContactInfoAssignment,
    ContactInfoGeneral,
    DialogEditAssociateContactInfo, DialogConfirm,
    VcSwitch, ContactInfoCard, GroupAvatar, AvatarWithStatus,
    ContentNotFound
  }
})
export default class ContactInfo2 extends mixins(Notifications, ContainerCalc) {
  @Prop() readonly contactId!: string
  @Prop({default: false}) readonly inChatInfo!: boolean

  showEditContactInfoDialog: boolean = false;
  showChangeVisibilityDialog: boolean = false;
  showDeleteContact: boolean = false;
  selectedTab: number = 0;
  unsubscribe?: Unsubscribe | null;

  @Getter directoryOriginals!: BusinessContact[];
  @Getter selectedContact;
  @Getter chatsError
  @Getter chatsBusy

  @Action selectContact;
  @Action shareContactLink;
  @Action createInnerChat;
  @Action loadBackingContacts;

  get isAdmin() {
    return applicationStore.isAdmin
  }

  get t2bUser() {
    return profileStore.t2bUser;
  }

  get menu() {
    const visible = !!this.selectedContact?.rules?.VISIBILITY?.visible;
    const menuList: any[] = []
    menuList.push({ title: 'Edit', index: 0 })
    menuList.push({
      title: visible ? 'Hide' : 'Make visible',
      index: 1,
      value: visible
    })
    menuList.push({ title: 'Remove', index: 3, alert: true})
    return menuList
  }

  get showSendMessage() {
    return this.group || this.selectedContact?.associate?.id !== this.t2bUser!.id;
  }

  get group() {
    return this.selectedContact?.type === 2;
  }

  get title(): string {
    return this.selectedContact?.name;
  }

  get subtitle(): string {
    return this.selectedContact?.position
        || this.selectedContact?.description
        || (this.selectedContact?.path && this.selectedContact.path[0])
        || this.selectedContact?.business.name;
  }

  get photoUrl(): string {
    return this.selectedContact?.photoUrl?.normal;
  }

  get online(): boolean {
    if (this.group) {
      return this.selectedContact?.contacts?.find(item => !!item.associate?.status?.online);
    }
    return !!this.selectedContact?.associate?.status?.online;
  }

  get abbreviation() {
    return twoChars(this.title);
  }

  get rating() {
    return (this.selectedContact?.stats?.avgRating || 0.0).toFixed(2);
  }

  get reviewsCount() {
    return this.selectedContact?.stats?.cases || 0;
  }

  get invisible() {
    return !this.selectedContact?.rules?.VISIBILITY?.visible;
  }

  get titleConfirm() {
    return `Do you want to remove contact ‘${this.title}’ ?`
  }

  onBackClicked() {
    if (this.inChatInfo) {
      this.selectContact(null);
      this.$emit('on-back-to-chat')
      return
    }
    this.onBackToDirectory()
  }

  onBackToDirectory() {
    this.selectContact(null);
    this.$router.push({name: 'directory', params: {}});
  }

  async onMenuItemClicked(menuItem) {
    switch (menuItem.index) {
      case 0: {
        this.showEditContactInfoDialog = true;
        break;
      }
      case 1: {
        await directoryStore.changeContactVisibility({
          id: this.selectedContact.id,
          visible: !menuItem.value
        });
        break;
      }
      case 3: {
        this.showDeleteContact = true;
        break;
      }
      default:
    }
  }

  async shareContact() {
    if (!this.selectedContact) {
      return;
    }
    try {
      const shortLink = await this.shareContactLink(this.selectedContact.id);
      await copyTextToClipboard(shortLink);
      this.showInfo('Copied to clipboard');
    } catch (err: any) {
      console.error(`share contact. error: ${err}`);
      this.showIssue(err.message);
    }
  }

  async sendMessage() {
    if (!this.selectedContact) {
      return;
    }
    const chatId = await this.createInnerChat({contactIds: [this.selectedContact.id]});
    if (!!chatId) {
      await this.$router.push({
        name: 'inner-chat',
        params: {chatId},
        query: {type: 'inner', subtype: 'personal'}
      });
    } else {
      this.showIssue(this.chatsError);
    }
  }

  onDeleteContact() {
    if (!this.selectedContact) {
      return;
    }
    directoryStore.deleteContact2(this.selectedContact.id);
    this.onBackToDirectory();
  }

  onChangesSaved() {
    this.showInfo('Changes saved');
  }

  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  created() {
    if (this.inChatInfo) {
      this.loadContact(this.contactId)
    }
  }

  async loadContact(contactId: string) {
    this.showDeleteContact = false
    unsubscribeSafe(this.unsubscribe)
    await directoryStore.loadContactById(contactId);
    this.unsubscribe = await this.loadBackingContacts(this.selectedContact);
  }

  async beforeRouteUpdate(to: Route, from: Route, next) {
    await this.loadContact(to.params.contactId)
    next();
  }

  async beforeRouteEnter(to: Route, from: Route, next) {
    await directoryStore.loadContactById(to.params.contactId);
    next(async (vm) => {
      vm.unsubscribe = await vm.loadBackingContacts(vm.selectedContact);
    });
  }
}
