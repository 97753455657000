




























































































































































import {Component, Prop, Watch} from 'vue-property-decorator';
import BaseDialog from '@/components/BaseDialog.vue';
import {Action, Getter} from 'vuex-class';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import GroupAvatar from '@/components/GroupAvatar.vue';
import {AssociateAccount, BusinessContact} from '@/domain/model/types';
import {storage} from '@/plugins/firebase.init';
import {mixins} from 'vue-class-component';
import BaseApplication from '@/components/mixins/BaseApplication';
import {ref, uploadBytes} from 'firebase/storage';

@Component({
  name: 'dialog-edit-associate-contact-info',
  components: {GroupAvatar, AvatarWithStatus, BaseDialog}
})
export default class DialogEditAssociateContactInfo extends mixins(BaseApplication) {
  @Prop() value!: boolean;
  @Prop() group!: boolean;
  @Prop({default: ''}) photoUrl!: string;

  @Getter selectedContact;
  srcContact?: BusinessContact | null;
  srcAssociate?: AssociateAccount | null;

  associateImageFile: File | null = null;
  associateImageUrl: string | null = null;

  title: string = 'Edit Associate Contact Info';
  currentInfo: string = 'contact_info';
  nextInfoButton: string = 'Edit Associate\' Private Info';
  disable: boolean = true
  saving: boolean = false;
  contactPosition: any | string = ''
  contactName: any | string = ''
  contactEmail: any | string = ''
  contactPhoneNumber: any | string = ''

  @Action saveContactInfoChanges;
  @Action savePrivateInfoChanges;
  @Action uploadProfilePhoto;

  get name() {
    return this.selectedContact?.name
  }

  get disableButton() {
    return this.saving || (!this.contactInfoChanged && !this.privateInfoChanged && !this.avatarPhotoChanged) || this.disable
  }

  get avatar() {
    return this.associateImageUrl || this.photoUrl;
  }

  get contactInfoChanged(): boolean {
    return !!this.srcContact &&
        ((this.contactName !== this.srcContact.name) ||
            (this.contactEmail !== this.srcContact.email) ||
            (this.contactPosition !== this.srcContact.position) ||
            (this.contactPhoneNumber !== this.srcContact.phoneNumber));
  }

  get privateInfoChanged(): boolean {
    return !!this.srcAssociate && !!this.srcAssociate.id &&
        ((this.selectedContact.associate.fullName !== this.srcAssociate.fullName) ||
            (this.selectedContact.associate.email !== this.srcAssociate.email) ||
            (this.selectedContact.associate.address !== this.srcAssociate.address) ||
            (this.selectedContact.associate.phoneNumber !== this.srcAssociate.phoneNumber) ||
            (this.selectedContact.associate.zipCode !== this.srcAssociate.zipCode) ||
            (this.selectedContact.associate.ssn !== this.srcAssociate.ssn));
  }

  get avatarPhotoChanged() {
    return !!this.associateImageFile && !!this.associateImageUrl;
  }

  get show() {
    return this.value;
  }

  set show(value: boolean) {
    this.$emit('input', value);
  }

  @Watch('contactPosition')
  onChangePosition() {
    const contactInfo: any = this.$refs.contactInfo;
    this.disable = !contactInfo.validate()
  }

  @Watch('contactName')
  onChangeName() {
    const contactInfo: any = this.$refs.contactInfo;
    this.disable = !contactInfo.validate()
  }

  @Watch('contactEmail')
  onChangeEmail() {
    const contactInfo: any = this.$refs.contactInfo;
    this.disable = !contactInfo.validate()
  }

  @Watch('contactPhoneNumber')
  onChangePhoneNumber() {
    const contactInfo: any = this.$refs.contactInfo;
    this.disable = !contactInfo.validate()
  }

  async onSaveChanges() {
    this.saving = true;
    try {
      const contactInfo: any = this.$refs.contactInfo;
      if (this.contactInfoChanged && contactInfo.validate()) {
        const result = await this.saveContactInfoChanges(Object.assign(
            {},
            this.selectedContact,
            {
              position: this.contactPosition,
              description: this.contactPosition,
              name: this.contactName,
              email: this.contactEmail,
              phoneNumber: this.contactPhoneNumber,
            }
        ))
        console.log(`saved contact info changes: ${result}`);
      }
      const privateInfo: any = this.$refs.privateInfo;
      if (!this.group && this.privateInfoChanged && privateInfo.validate()) {
        const result = await this.savePrivateInfoChanges(this.selectedContact.associate);
        console.log(`saved private info changes: ${result}`);
      }
      if (this.avatarPhotoChanged) {
        // Upload profile photo
        const uploadData = {
          contactId: this.selectedContact.id,
          imageFile: this.associateImageFile
        };
        const url = `businesses/${this.business!.id}/directory/${uploadData.contactId}/photo/${uploadData.imageFile!.name}`;
        const imagesRef = ref(storage, url);
        const result = await uploadBytes(imagesRef, uploadData.imageFile!);
        console.log(`Profile photo has been uploaded - ${result.ref}`);
      }
      this.show = false;
      this.$emit('on-changes-saved');
    } catch (err) {
      this.$emit('on-error');
      console.log(`error: ${err}`);
    }
    this.saving = false;
  }

  onInfoWindowChanged() {
    switch (this.currentInfo) {
      case 'contact_info':
        this.currentInfo = 'private_info';
        this.nextInfoButton = 'Edit Associate\' Contact Info';
        this.title = 'Edit Associate\' Private Info';
        break;
      case 'private_info':
        this.currentInfo = 'contact_info';
        this.nextInfoButton = 'Edit Associate\' Private Info';
        this.title = 'Edit Associate\' Contact Info';
        break;
      default:
        this.currentInfo = 'contact_info';
        this.nextInfoButton = 'Edit Associate\' Private Info';
        this.title = 'Edit Associate\' Contact Info';
    }
  }

  onAssociateImageFileSelected(event) {
    console.log('onAssociateImageFileSelected');
    this.associateImageFile = event.target.files[0];
    if (this.associateImageFile) {
      console.log(this.associateImageFile.name);
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.associateImageUrl = e.target.result || null;
      };
      reader.readAsDataURL(this.associateImageFile);
    }
  }

  onCloseDialog() {
    if (this.contactInfoChanged) {
      Object.assign(this.selectedContact, this.srcContact)
    }
    if (this.privateInfoChanged) {
      Object.assign(this.selectedContact.associate, this.srcAssociate)
    }
  }

  created() {
    // TODO: implement right clone
    this.srcContact = Object.assign({}, this.selectedContact);
    this.contactPosition = this.selectedContact.position || this.selectedContact.description || ''
    this.contactName = this.srcContact?.name
    this.contactEmail = this.srcContact?.email
    this.contactPhoneNumber = this.srcContact?.phoneNumber
    this.srcAssociate = Object.assign({}, this.selectedContact.associate);
  }
}
